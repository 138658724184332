import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Container } from "react-awesome-styled-grid"

import {
  Layout,
  SEO,
  ProjectsList,
  LeadText,
  HeaderSpacer,
  ProjectsTags,
} from "../components"

const WorkPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMarkdownRemark.nodes
  const [selectedTag, setTag] = useState("all")
  const [loadedProjects, setLoadedProjects] = useState()

  const handleTagClick = tag => {
    setTag(tag)
  }
  useEffect(() => {
    if (selectedTag === "all") {
      setLoadedProjects(projects)
    } else {
      const filteredProjects = projects.filter(project =>
        project.frontmatter.tags.includes(selectedTag)
      )
      setLoadedProjects(filteredProjects)
    }
  }, [selectedTag, projects])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Work" image="/studio.jpg" />
      <HeaderSpacer />
      <LeadText
        title="Details matter"
        text="We strive to find ways to engage and surprise through a tailor-made approach to branding."
      />
      <Container>
        <ProjectsTags>
          <button
            disabled={selectedTag === "all"}
            onClick={() => handleTagClick("all")}
          >
            all
          </button>
          /
          <button
            disabled={selectedTag === "identity"}
            onClick={() => handleTagClick("identity")}
          >
            identity
          </button>
          /
          <button
            disabled={selectedTag === "packaging"}
            onClick={() => handleTagClick("packaging")}
          >
            packaging
          </button>
          /
          <button
            disabled={selectedTag === "digital"}
            onClick={() => handleTagClick("digital")}
          >
            digital
          </button>
        </ProjectsTags>
        <ProjectsList mb={4} projects={loadedProjects} />
      </Container>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          extra
          tags
          card {
            publicURL
          }
        }
      }
    }
  }
`

